import React from 'react'
import 'date-fns'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { withTranslate } from 'react-redux-multilingual'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import Cards from 'react-credit-cards'
import InputAdornment from '@material-ui/core/InputAdornment'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import LockIcon from '@material-ui/icons/Lock'
import TextField from '@material-ui/core/TextField'
import CurrencyFormat from 'react-currency-format'
import {
  floatToCurrency,
  formatCreditCardNumber,
  formatCVV,
  formatExpirationDate,
  getIssuer
} from '../helpers/credit_card'
import * as CreditCardValidator from 'card-validator'
import { Input, NativeSelect } from '@material-ui/core'
import StringHelper from '../helpers/string-helper';

class BookingInfoPayment extends React.Component {
  state = {
    focused: '',
    formData: null,
    selectedInstallmentsChoice: -1,
    ccIssuer: '',
    ccIssuerAcceptableCvvLength: [3],
    installmentsFeeInformation: ''
  }

  constructor(props) {
    super(props)
    this.paymentField = this.paymentField.bind(this)
  }

  //region Custom Fields
  paymentField(props) {
    const { inputRef, ...other } = props

    return (
      <CurrencyFormat
        {...other}
        prefix={'R$ '}
        suffix={''}
        thousandSeparator={'.'}
        decimalSeparator={','}
        allowNegative={false}
        type={'tel'}
        decimalScale={2}
        fixedDecimalScale={true}
        onValueChange={this.handlePaymentAmountChange}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null)
        }}
      />
    )
  }
  //endregion

  //region Events
  updateRef(name, ref) {
    this[name] = ref
  }

  handleInputFocus = ({ target }) => {
    let name = ''

    switch (target.name) {
      case 'cc_number': {
        name = 'number'
        break
      }
      case 'cc_name': {
        name = 'name'
        break
      }
      case 'cc_valid_thru': {
        name = 'expiry'
        break
      }
      case 'cc_code': {
        name = 'cvc'
        break
      }
      default: {
        name = ''
        break
      }
    }

    this.setState({ focused: name })
  }

  handleInputChange = ({ target }) => {
    if (target.name === 'cc_number') {
      
      const issuer = getIssuer(target.value);
      this.setState({ 
        ccIssuer: issuer, 
        ccIssuerAcceptableCvvLength: (issuer === 'amex') ? [3,4] : [3] 
      });
      target.value = formatCreditCardNumber(target.value);

      // re-run calculation for installments message
      setTimeout(()=>{
        const { payment: { cc_installments } } = this.props
        if( StringHelper.isNotEmpty(cc_installments)){
          this.handleInstallmentsChoose(cc_installments);
        }
      }, 500);

    } else if (target.name === 'cc_valid_thru') {

      target.value = formatExpirationDate(target.value)

    } else if (target.name === 'cc_code') {

      target.value = formatCVV(target.value)
    }

    this.props.handlePaymentChange(target.name, target.value)
  }

  handleIstallmentsChange = ({ target }) => {
    this.props.handlePaymentChange(target.name, target.value);
    this.setState({ selectedInstallmentsChoice: target.value });
    this.handleInstallmentsChoose(target.value);
  }

  handleInstallmentsChoose = (installmentsNumber) => {

    if(parseInt(installmentsNumber) === 1){

      this.setState({ installmentsFeeInformation: null })

    } else if(parseInt(installmentsNumber) > 1){

      const { paymentInfo, translate } = this.props
      const { payment: { cc_payment_amount } } = this.props
  
      const selectedInstallment = (paymentInfo.payment_gateway_installments.filter(installment => installment.value === parseInt(installmentsNumber)))[0];
      
      let fee = 0.0,
          totalInQuotes = 0.0,
          quotesFee = 0.0;
      let installmentsFeeInformation = ''; 
      if( this.state.ccIssuer === 'mastercard'){
        fee = parseFloat(selectedInstallment.rate_mastercard);
        totalInQuotes = parseFloat(cc_payment_amount) * (1 + fee/100);
        quotesFee = totalInQuotes - cc_payment_amount;
        installmentsFeeInformation = translate('installmentsFee') + ' : ' + floatToCurrency(quotesFee) + '  (' + selectedInstallment.rate_mastercard + '%)';
        installmentsFeeInformation += " <br> "  + translate('totalInQuotes') + ' : ' + floatToCurrency(totalInQuotes);
      } else if( this.state.ccIssuer === 'visa'){
        fee = parseFloat(selectedInstallment.rate_visa);
        totalInQuotes = parseFloat(cc_payment_amount) * (1 + fee/100);
        quotesFee = totalInQuotes - cc_payment_amount;
        installmentsFeeInformation = translate('installmentsFee') + ' : ' + floatToCurrency(quotesFee) + ' (' + selectedInstallment.rate_visa + '%)';
        installmentsFeeInformation += " <br> "  + translate('totalInQuotes') + ' : ' + floatToCurrency(totalInQuotes);
      } else {
        fee = parseFloat(selectedInstallment.rate);
        totalInQuotes = parseFloat(cc_payment_amount) * (1 + fee/100);
        quotesFee = totalInQuotes - cc_payment_amount;
        installmentsFeeInformation = translate('installmentsFee') + ' : ' + floatToCurrency(quotesFee) + ' (' + selectedInstallment.rate + '%)';
        installmentsFeeInformation += " <br> "  + translate('totalInQuotes') + ' : ' + floatToCurrency(totalInQuotes);
      }

      this.setState({ installmentsFeeInformation: (quotesFee === 0 ) ? '' : installmentsFeeInformation })
    }
  }

  //endregion

  render() {
    const { classes, translate, paymentInfo } = this.props
    const {
      payment: { cc_number, cc_name, cc_valid_thru, cc_code, cc_payment_amount, cc_installments }
    } = this.props
    const { focused } = this.state

    let cc_base_payment_amount = floatToCurrency(cc_payment_amount)

    const locale = {
      number: translate('ccCardNumberLabel').toLowerCase(),
      name: translate('ccFullNameLabel').toLowerCase(),
      valid: translate('ccValidThruLabel').toLowerCase(),
      cvc: translate('ccCodeLabel').toLowerCase(),
      installments: translate('installments').toLowerCase(),
    }

    const placeholders = {
      number: translate('ccCardNumberLabel'),
      name: translate('ccYourName'),
      valid: translate('ccValidThruLabel'),
      cvc: translate('ccCodeLabel'),
      installments: translate('installments'),
    }

    return (
      <React.Fragment>
        <Grid container className={classes.root}>
          <Grid className={classes.gridItemClasses} item xs={12}>
            <Cards
              number={cc_number}
              name={cc_name}
              expiry={cc_valid_thru}
              cvc={cc_code}
              focused={focused}
              locale={locale}
              placeholders={placeholders}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.gridItemClasses}>
            <TextField
              id="cc_number"
              name="cc_number"
              label={translate('ccCardNumberLabel')}
              value={cc_number}
              error={!this.isValid('cc_number')}
              helperText={
                !this.isValid('cc_number')
                  ? translate('ccCardNumberInputHelperText')
                  : ''
              }
              onChange={this.handleInputChange}
              onFocus={this.handleInputFocus}
              pattern="[\d| ]{16,22}"
              autoFocus
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CreditCardIcon />
                  </InputAdornment>
                ),
                maxLength: 20
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.gridItemClasses}>
            <TextField
              id="cc_name"
              name="cc_name"
              label={translate('ccFullNameLabel')}
              value={cc_name}
              error={!this.isValid('cc_name')}
              helperText={
                !this.isValid('cc_name')
                  ? translate('ccFullNameInputHelperText')
                  : ''
              }
              onChange={this.handleInputChange}
              onFocus={this.handleInputFocus}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircleIcon />
                  </InputAdornment>
                ),
                maxLength: 50,
                readOnly: !this.canValidate()
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.gridItemClasses}>
            <TextField
              id="cc_valid_thru"
              name="cc_valid_thru"
              label={translate('ccValidThruLabel')}
              value={cc_valid_thru}
              error={!this.isValid('cc_valid_thru')}
              helperText={
                !this.isValid('cc_valid_thru')
                  ? translate('ccValidThruInputHelperText')
                  : ''
              }
              onChange={this.handleInputChange}
              onFocus={this.handleInputFocus}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CalendarTodayIcon />
                  </InputAdornment>
                ),
                maxLength: 50,
                readOnly: !this.canValidate()
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.gridItemClasses}>
            <TextField
              id="cc_code"
              name="cc_code"
              label={translate('ccCodeLabel')}
              value={cc_code}
              error={!this.isValid('cc_code')}
              helperText={
                !this.isValid('cc_code')
                  ? translate('ccCodeInputHelperText')
                  : ''
              }
              onChange={this.handleInputChange}
              onFocus={this.handleInputFocus}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                maxLength: 5,
                readOnly: !this.canValidate()
              }}
            />
          </Grid>

          {paymentInfo.payment_gateway_enabled && paymentInfo.payment_gateway_installments_enabled && 
          (
            <Grid item xs={12} sm={6} className={classes.gridItemClasses}>
              <NativeSelect
                name="cc_installments"
                value={cc_installments}
                onChange={this.handleIstallmentsChange}
                input={
                  <Input name="cc_installmentsph" id="installments-label-placeholder" />
                }
                fullWidth
                required={true}
                inputProps={{ id: 'installments-required', style: { height: 35 } }}
              >
                <option value="-1" disabled>
                  {translate('installmentsChoose')}
                </option>
                {paymentInfo.payment_gateway_installments.map((installment, index) => (
                  <option value={installment.value} key={index}>
                    {installment.value}{'x'}
                  </option>
                ))}
              </NativeSelect>
              
            </Grid>
          )}

          <Grid item xs={12} sm={6} className={classes.gridItemClasses}>
            <TextField
              id="cc_payment_amount"
              name="cc_payment_amount"
              label={translate('ccPaymentAmount')}
              value={cc_base_payment_amount}
              helperText={''}
              readOnly
              fullWidth
            />
          </Grid>

          {paymentInfo.payment_gateway_enabled && paymentInfo.payment_gateway_installments_enabled && 
          (
            <Grid item xs={12} sm={12} className={classes.installmentsInfo}>
              {this.state.installmentsFeeInformation && (
                <div dangerouslySetInnerHTML={{__html: this.state.installmentsFeeInformation}} />
              )}
            </Grid>
          )}
        </Grid>
      </React.Fragment>
    )
  }

  canValidate = () => {
    return this.props.payment.cc_number !== ''
  }

  isValid = (field) => {
    const canValidate = this.canValidate(),
      globalValid = this.props.valid

    let valid = true

    if (canValidate) {
      switch (field) {
        case 'cc_number': {
          const numberValidation = CreditCardValidator.number(
            this.props.payment.cc_number
          )
          valid = numberValidation.isValid
          break
        }

        case 'cc_name': {
          valid = this.props.payment.cc_name !== ''
          break
        }

        case 'cc_valid_thru': {
          const validThruValidation = CreditCardValidator.expirationDate(
            this.props.payment.cc_valid_thru
          )
          valid = validThruValidation.isValid
          break
        }

        case 'cc_code': {
          const codeValidation = CreditCardValidator.cvv(
            this.props.payment.cc_code,
            this.state.ccIssuerAcceptableCvvLength
          )
          valid = codeValidation.isValid
          break
        }

        case 'cc_payment_amount': {
          valid =
            parseFloat(this.props.payment.cc_payment_amount) <=
            parseFloat(this.props.bookingInfo.debit)
          break
        }

        default: {
          valid = true
          break
        }
      }
    }

    return !globalValid && valid
  }
}

const styles = (theme) => ({
  root: {
    padding: 0,
    [theme.breakpoints.up(400)]: {
      padding: 20,
    }
  },
  gridItemClasses: {
    paddingTop: 15,
    paddingBottom: 15
  },
  installmentsInfo: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: '1.2em'
  }
})

BookingInfoPayment.propTypes = {
  classes: PropTypes.object.isRequired
}

export default compose(
  withStyles(styles),
  connect()
)(withTranslate(BookingInfoPayment))
